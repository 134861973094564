import React from "react";
import styled from "@emotion/styled";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
`;

function Loader() {
  return (
    <Root>
      <ClimbingBoxLoader color="white" size={17} />
    </Root>
  );
}

export default Loader;
