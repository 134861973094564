import React, { Suspense } from "react";
import createTheme from "./theme";
import useTheme from "./hooks/useTheme";
import { CacheProvider } from "@emotion/react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AuthProvider } from "./contexts/JWTContext";
import createEmotionCache from "./utils/createEmotionCache";
import { HelmetProvider, Helmet } from "react-helmet-async";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import NotificationAlerts from "./components/alerts/NotificationAlerts";
import Router from "./routes";
import Loader from "./components/Loader";

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const { theme } = useTheme();

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | Chat With PDF"
          defaultTitle="Chat With PDF - Admin Dashboard"
        />
        <NotificationAlerts />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiThemeProvider theme={createTheme(theme)}>
            <AuthProvider>
              <Suspense fallback={<Loader />}>
                <Router />
              </Suspense>
            </AuthProvider>
          </MuiThemeProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
