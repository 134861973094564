import React, { lazy } from "react";
import AuthGuard from "./components/guards/AuthGuard";
import { Navigate, useRoutes } from "react-router-dom";
import useAuth from "./hooks/useAuth";

const DashboardLayout = lazy(() => import("./layouts/Dashboard"));
const AuthLayout = lazy(() => import("./layouts/Auth"));
const SignIn = lazy(() => import("./pages/auth/SignIn"));
const Customers = lazy(() => import("./pages/customers/Customers"));
const RequestLogs = lazy(() => import("./pages/requestLogs/RequestLogs"));
const Documents = lazy(() => import("./pages/documents/Documents"));
const Organization = lazy(() => import("./pages/organization"));
const Settings = lazy(() => import("./pages/settings/Settings"));
const Plans = lazy(() => import("./pages/plans/Plans"));

const superAdminRoutes = [
  { element: <Navigate to="/organization" />, index: true },
  { path: "organization", element: <Organization /> },
  { path: "plans", element: <Plans /> },
];
const organisationAdminRoutes = [
  { element: <Navigate to="/users" />, index: true },
  { path: "users", element: <Customers /> },
  { path: "invitations", element: <RequestLogs /> },
  { path: "documents", element: <Documents /> },
  { path: "settings", element: <Settings /> },
];

export default function Router() {
  const { user } = useAuth();
  let currentRoutes = [];
  if (user?.userType === 1) currentRoutes = superAdminRoutes;
  else if (user?.userType === 2) currentRoutes = organisationAdminRoutes;

  const routes = useRoutes([
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: currentRoutes,
    },
    {
      path: "auth",
      element: <AuthLayout />,
      children: [
        {
          element: <Navigate to="/auth/sign-in/:access_token?" />,
          index: true,
        },
        { path: "sign-in", element: <SignIn /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);

  return routes;
}
